<template>
  <file-list-view :files="privateFiles" :context="context"/>
</template>

<script>
import {computed, onMounted} from 'vue';
import FileListView from "./FileListView/Index";
import {useStore} from 'vuex';

export default {
  name: "PrivateFileList",
  components: {
    FileListView,
  },
  setup() {
    const store = new useStore();
    const context = 'private';

    const privateFiles = computed(() => store.state.fileList.privateFiles);

    onMounted(() => {
      store.dispatch('fileList/fetchPrivate');
    })

    return {
      privateFiles,
      context,
    }
  }
}
</script>

<style scoped>

</style>