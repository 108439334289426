<template>
  <a-button type="primary" @click="createSecureLink(record)">
    <LinkOutlined/>
    Создать ссылку
  </a-button>
  <a-modal
      v-model:visible="visible"
      title="Ссылка успешно создана!"
      :centered="true"
      :afterClose="afterCloseDialog"
  >
    <template #footer>
      <a-button key="cancel" @click="handleCancel">Отмена</a-button>
      <a-button key="submit-link" type="primary" :loading="loading" @click="copyLink">Скопировать ссылку</a-button>
      <a-button key="submit-message" type="primary" :loading="loading" @click="copyMessage">Скопировать сообщение
      </a-button>
    </template>
    <ul>
      <li>Имя файла: {{ record.name }}</li>
      <li>Размер: {{ humanFileSize(record.size_bytes) }}</li>
      <li>Ссылка действительна до: {{ humanizeDate(secureLink.expired_at) }}</li>
    </ul>
  </a-modal>
</template>

<script>
import {computed, ref} from 'vue';
import {createLink, createMessage, humanFileSize, humanizeDate} from "@/_helpers";
import {toClipboard} from '@soerenmartius/vue3-clipboard';
import {notificationService, secureLinkService} from "@/_services";
import {useStore} from 'vuex';
import {LinkOutlined} from '@ant-design/icons-vue';

export default {
  name: "SecureLinkDialog",
  props: ['record', 'urlExpires', 'context', 'afterCloseDialog'],
  components: {
    LinkOutlined,
  },

  setup(props) {
    const visible = ref(false);

    const store = new useStore();

    const loading = computed(() => {
      return store.state.loading.isLoading;
    });

    const secureLink = ref({})

    const createSecureLink = row => {
      secureLinkService.createLink(row.name, props.urlExpires * 86400, props.context).then(createLinkResponse => {
        visible.value = true;
        secureLink.value = createLinkResponse;
      }).catch(() => {
        visible.value = false;
      });
    };

    const copyLink = () => {
      const secureLinkText = createLink(secureLink.value.data)
      toClipboard(secureLinkText).then(() => notificationService.sendInfo('Ссылка скопирована'));
      visible.value = false;
    }

    const copyMessage = () => {
      const extraText = createMessage(props.record, secureLink.value)
      toClipboard(extraText).then(() => notificationService.sendInfo('Сообщение скопировано'));
      visible.value = false;
    }

    const handleCancel = () => {
      visible.value = false;
      secureLink.value = {};
    }

    return {
      loading,
      visible,
      humanFileSize,
      humanizeDate,
      copyMessage,
      createSecureLink,
      secureLink,
      copyLink,
      handleCancel,
    }
  }
}
</script>

<style scoped>

</style>