<template>
  <file-list-view :files="publicFiles" :context="context"/>
</template>

<script>
import {computed, onMounted} from 'vue';
import FileListView from './FileListView/Index';
import {useStore} from 'vuex';

export default {
  name: "PublicFileList",
  components: {
    FileListView,
  },
  setup() {
    const store = new useStore();
    const context = 'public';

    const publicFiles = computed(() => store.state.fileList.publicFiles);

    onMounted(() => {
      store.dispatch('fileList/fetchPublic');
    })

    return {
      publicFiles,
      context,
    }
  }
}
</script>

<style scoped>

</style>