<template>
  <a-result status="404" title="404" sub-title="Страница не найдена">
    <template #extra>
      <a-button type="primary" @click="handleBack">На главную</a-button>
    </template>
  </a-result>
</template>


<script>
import router from "../router";

export default {
  name: "PageNotFound",
  setup() {
    const handleBack = () => {
      router.push('/');
    }
    return {
      handleBack,
    }
  }
}
</script>

<style scoped>

</style>