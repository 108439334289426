<template>
  <a-layout :style="{height: '100%'}">
    <a-layout-header v-if="isAuthenticated"
                     :style="{ position: 'fixed', zIndex: 1, width: '100%' }">
      <a-menu
          theme="dark"
          mode="horizontal"
          v-model:selectedKeys="currentMenuSelected"
          :style="{ lineHeight: '64px' }"
      >
        <a-dropdown v-model:visible="visible">
          <a class="ant-dropdown-link" @click.prevent>
            <a-avatar style="margin-right: 20px">
              <template #icon>
                <UserOutlined/>
              </template>
            </a-avatar>
          </a>
          <template #overlay>
            <div>
              <a-card :title="currentUser.username" style="width: 300px">
                <p>Имя: {{ currentUser.full_name }}</p>
                <p>
                  Приват:
                  <a-tag v-if="currentUser.private" color="success">Разрешен
                  </a-tag>
                  <a-tag v-else color="error">Запрещен</a-tag>
                </p>
                <p>
                  Документы:
                  <a-tag v-if="currentUser.documents" color="success">Разрешен
                  </a-tag>
                  <a-tag v-else color="error">Запрещен</a-tag>
                </p>
                <a-button
                    block
                    type="primary"
                    @click="handleLogout"
                >
                  Выйти
                </a-button>
              </a-card>
            </div>
          </template>
        </a-dropdown>
        <a-menu-item key="Home">
          <router-link class='link' :to='{"name": "Home"}'>
            <FileProtectOutlined/>
            Public
          </router-link>
        </a-menu-item>
        <a-menu-item key="Private">
          <router-link v-if="isPrivate" class='link' :to='{"name": "Private"}'>
            <FileProtectOutlined/>
            Private
          </router-link>
        </a-menu-item>
        <a-menu-item key="Documents">
          <router-link v-if="isDocuments" class='link' :to='{"name": "Documents"}'>
            <FileProtectOutlined/>
            Documents
          </router-link>
        </a-menu-item>
        <a-menu-item key="MediaSave">
          <router-link class='link' :to='{"name": "MediaSave"}'>
            <YoutubeOutlined/>
            Сохранить из видеохостинга
          </router-link>
        </a-menu-item>
      </a-menu>
    </a-layout-header>
    <a-layout-content :style="{ padding: '0 50px', marginTop: '64px' }">
      <router-view/>
    </a-layout-content>
    <help-modal v-if="isAuthenticated"/>
    <a-layout-footer style="text-align: center">
      ver. {{ version }} &copy; {{ new Date().getFullYear() }}
    </a-layout-footer>
  </a-layout>
</template>

<script>
import {computed, defineComponent, onMounted, ref, watch} from 'vue';
import HelpModal from './_HelpDrawer';
import {useStore} from 'vuex';
import {
  FileProtectOutlined,
  UserOutlined,
  YoutubeOutlined
} from '@ant-design/icons-vue';
import {useRoute} from 'vue-router';

export default defineComponent({
  components: {
    HelpModal,
    UserOutlined,
    FileProtectOutlined,
    YoutubeOutlined,
  },
  setup() {
    document.title = "Публичные ссылки - Ямал-Медиа";
    const store = new useStore();
    const route = useRoute()

    onMounted(() => {
      store.dispatch('authentication/getUserInfo');
      store.dispatch('appSettings/fetchSettings');
    })

    const currentMenuSelected = ref(['Home']);

    watch(
        () => route.name, () => {
          currentMenuSelected.value = [route.name]
        }
    )

    const currentUser = computed(() => {
      const user = store.state.authentication.user;
      return user === null ? {} : user;
    })

    const isAuthenticated = computed(() => {
      return store.getters["authentication/loggedIn"];
    });

    const isPrivate = computed(() => {
      const user = store.state.authentication.user;
      return user === null ? false : user.private;
    })

    const isDocuments = computed(() => {
      const user = store.state.authentication.user;
      return user === null ? false : user.documents;
    })

    const version = computed(() => {
      const settings = store.state.appSettings.settings;
      if (!settings) {
        store.dispatch('appSettings/fetchSettings');
      }
      return settings.version;
    })
    const visible = ref(false);

    const handleLogout = () => {
      visible.value = false;
      store.dispatch('authentication/logout')
    }

    return {
      currentMenuSelected,
      isAuthenticated,
      isPrivate,
      isDocuments,
      version,
      visible,
      currentUser,
      handleLogout,
    };
  }
});
</script>
<style>

</style>