import {createStore} from 'vuex'
import authentication from "./authentication";
import fileList from "./fileList";
import appSettings from "./app-settings";
import loading from "./loading";
import mediaInfo from "./media-info";

export const store = createStore({
    modules: {
        authentication,
        fileList,
        appSettings,
        loading,
        mediaInfo,
    },
});

