<template>
  <file-list-view :files="documentsFiles" :context="context"/>
</template>

<script>
import {computed, onMounted} from 'vue';
import FileListView from "./FileListView/Index";
import {useStore} from 'vuex';

export default {
  name: "DocumentsFileList",
  components: {
    FileListView,
  },
  setup() {
    const store = new useStore();
    const context = 'documents';

    const documentsFiles = computed(() => store.state.fileList.documentsFiles);

    onMounted(() => {
      store.dispatch('fileList/fetchDocuments');
    })

    return {
      documentsFiles,
      context,
    }
  }
}
</script>

<style scoped>

</style>