const state = () => ({
    refCount: 0,
    isLoading: false
})

const getters = {}
const actions = {}

const mutations = {
    loading(state, isLoading) {
        if (isLoading) {
            state.refCount++;
            state.isLoading = true;
        } else if (state.refCount > 0) {
            state.refCount--;
            state.isLoading = (state.refCount > 0);
        }
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}