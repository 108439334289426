<template>
  <a-row type="flex" justify="space-around" align="middle">
    <a-descriptions
        v-if="authenticated"
        title="Информация о пользователе"
        :loading="loading"
    >
      <a-descriptions-item label="Логин">
        {{ currentUser.username }}
      </a-descriptions-item>
      <a-descriptions-item label="Полное имя">
        {{ currentUser.full_name }}
      </a-descriptions-item>
      <a-descriptions-item label="Приватный доступ">
        {{ currentUser.private ? "разрешен" : "запрещен" }}
      </a-descriptions-item>
      <a-descriptions-item label="Доступ к документам">
        {{ currentUser.documents ? "разрешен" : "запрещен" }}
      </a-descriptions-item>
      <a-descriptions-item label="Сессия активна до">
        {{ humanizeDate(sessionTtl) }}
      </a-descriptions-item>
      <a-descriptions-item label="Действия">
        <a-button
            block
            type="primary"
            @click="handleLogout"
        >
          Выйти
        </a-button>
      </a-descriptions-item>

    </a-descriptions>
    <a-card
        v-else
        :title="loading ? 'Идет авторизация...' : 'Пожалуйста, авторизуйтесь'"
        hoverable style="width: 300px"
        :loading="loading"
    >
      <a-form
          :model="formState"
          @finish="handleFinish"
      >
        <a-form-item>
          <a-input v-model:value="formState.username"
                   placeholder="Имя пользователя">
            <template #prefix>
              <UserOutlined style="color: rgba(0, 0, 0, 0.25)"/>
            </template>
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-input v-model:value="formState.password" type="password"
                   placeholder="Пароль">
            <template #prefix>
              <LockOutlined style="color: rgba(0, 0, 0, 0.25)"/>
            </template>
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-button
              type="primary" block
              html-type="submit"
              :disabled="!formReady"
          >
            Войти
          </a-button>
        </a-form-item>
      </a-form>
    </a-card>
  </a-row>
</template>
<script>
import {LockOutlined, UserOutlined} from '@ant-design/icons-vue';
import {computed, defineComponent, onMounted, reactive} from 'vue';
import {useStore} from 'vuex';
import {humanizeDate} from "../_helpers";

export default defineComponent({
  name: "LoginPage",
  setup() {
    document.title = "Публичные ссылки - Ямал-Медиа | Аутентификация";
    const store = new useStore();

    const formState = reactive({
      username: '',
      password: '',
    });

    const handleFinish = () => {
      if (formReady.value === true) {
        store.dispatch('authentication/login', formState)
      }
    };

    const handleLogout = () => {
      store.dispatch('authentication/logout')
    }

    const formReady = computed(() => {
      return formState.username !== '' && formState.password !== '';
    });

    const authenticated = computed(() => {
      return store.getters["authentication/loggedIn"];
    })

    const currentUser = computed(() => {
      const user = store.state.authentication.user;
      return user === null ? {} : user;
    })

    const sessionTtl = computed(() => {
      return store.state.authentication.token.exp;
    })

    const loading = computed(() => {
      return store.state.loading.isLoading;
    })

    onMounted(() => {
      if (authenticated.value) {
        store.dispatch('authentication/getUserInfo');
      }
    })

    return {
      formState,
      handleFinish,
      formReady,
      authenticated,
      handleLogout,
      currentUser,
      humanizeDate,
      sessionTtl,
      loading
    };
  },

  components: {
    UserOutlined,
    LockOutlined,
  },
});
</script>