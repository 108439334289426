import {humanFileSize, humanizeDate} from "./human";

export function createLink(linkPath) {
    return `${window.location.origin}/${linkPath}`
}

export function createMessage(record, secureLink) {
    const secureLinkText = createLink(secureLink.data)
    return `Имя файла: ${record.name}
Ссылка: ${secureLinkText}
Размер: ${humanFileSize(record.size_bytes)}
Ссылка Создана: ${humanizeDate(secureLink.created_at)}
Ссылка действительна до: ${humanizeDate(secureLink.expired_at)}`
}