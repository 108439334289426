export function humanizeDate(anyDate) {
    const moment = require('moment-timezone');
    const timezone = moment.tz.guess();
    let date = moment(anyDate);
    return date.tz(timezone).locale("ru").format("DD MMM YYYY H:mm")
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes, si = true, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' Б';
    }

    const units = si
        ? ['КБ', 'МБ', 'ГБ', 'ТБ', 'ПБ', 'ЭБ', 'ЗБ', 'ЕБ']
        : ['КиБ', 'МиБ', 'ГиБ', 'ТиБ', 'ПиБ', 'ЕиБ', 'ЗиБ', 'ЕиБ'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[u];
}

export function dateExpired(expiredAt) {
    const moment = require('moment-timezone');
    let dateExpiredAt = moment(expiredAt);
    return moment.now() < moment(dateExpiredAt);
}
