import {apiClient} from "@/_services/api-client";
import {store} from '../store';
import router from "../router";

export const userService = {
    login,
    logout,
    fetchUserInfo,
    refreshToken,
    parseJwt,
};

async function login(username, password) {
    store.commit("loading/loading", true);
    let formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    try {
        const response = await apiClient.post(`/api/v1/auth/token`, formData);
        localStorage.setItem('token', JSON.stringify(response.data));
        return response.data;
    } finally {
        store.commit("loading/loading", false);
        router.push('/');
    }
}

async function logout() {
    // remove user from local storage to log user out
    store.commit("loading/loading", true);
    const token = store.state.authentication.token;
    try {
        const response = await apiClient.post(`/api/v1/auth/token/revoke/${token.refresh_token}`);
        return response.data;
    } finally {
        store.commit("loading/loading", false);
        localStorage.removeItem('token');
        router.push('/login');
    }
}

// переменная для хранения запроса токена
// Избегание состояния гонки
let refreshTokenRequest = null;

async function refreshToken() {
    let token = JSON.parse(localStorage.getItem('token'));
    if (token === null) {
        console.log('token not set, skip refresh flow')
        return
    }
    // если не было запроса на обновление
    // создаем запрос и запоминаем его в переменную
    // для избежания race condition
    if (refreshTokenRequest === null) {
        refreshTokenRequest = apiClient.post(`api/v1/auth/token/refresh/${token.refresh_token}`);
    } else {
        console.log('waiting for prev. refresh token request...');
    }
    const response = await refreshTokenRequest;
    localStorage.setItem('token', JSON.stringify(response.data));
    refreshTokenRequest = null; // чистим запрос
    return response.data;
}

async function fetchUserInfo() {
    store.commit("loading/loading", true);
    try {
        const response = await apiClient.get(`/api/v1/auth/users/me`);
        return response.data;
    } finally {
        store.commit("loading/loading", false);
    }
}

function parseJwt(token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
}
