import {notificationService, secureLinkService} from '@/_services';

const state = () => ({
    publicFiles: [],
    privateFiles: [],
    documentsFiles: [],
})

const getters = {}

const actions = {
    fetchPublic({commit}) {
        secureLinkService.getAllPublic().then(files => {
                commit('setPublic', files);
                notificationService.sendInfo(`${files.length} файла(ов) получено`);
            }, error => {
                notificationService.sendError(error.response.data.detail);
            }
        );
    },
    fetchPublicFiltered({commit}, payload) {
        secureLinkService.getFilteredPublic(payload).then(files => {
                notificationService.sendInfo(`${files.length} файла(ов) получено`);
                commit('setPublic', files);
            }, error => {
                notificationService.sendError(error.response.data.detail);
            }
        );
    },
    fetchPrivate({commit}) {
        secureLinkService.getAllPrivate().then(files => {
                commit('setPrivate', files);
                notificationService.sendInfo(`${files.length} файла(ов) получено`);
            },
            error => {
                notificationService.sendError(error.response.data.detail);
            }
        );
    },
    fetchPrivateFiltered({commit}, payload) {
        secureLinkService.getFilteredPrivate(payload)
            .then(files => {
                    commit('setPrivate', files);
                    notificationService.sendInfo(`${files.length} файла(ов) получено`);
                }, error => {
                    notificationService.sendError(error.response.data.detail);
                }
            );
    },
    fetchDocuments({commit}) {
        secureLinkService.getAllDocuments().then(files => {
                commit('setDocuments', files);
                notificationService.sendInfo(`${files.length} файла(ов) получено`);
            },
            error => {
                notificationService.sendError(error.response.data.detail);
            }
        );
    },
    fetchDocumentsFiltered({commit}, payload) {
        secureLinkService.getFilteredDocuments(payload)
            .then(files => {
                    commit('setDocuments', files);
                    notificationService.sendInfo(`${files.length} файла(ов) получено`);
                }, error => {
                    notificationService.sendError(error.response.data.detail);
                }
            );
    },
}

const mutations = {
    setPublic(state, files) {
        state.publicFiles = files;
    },
    setPrivate(state, files) {
        state.privateFiles = files;
    },
    setDocuments(state, files) {
        state.documentsFiles = files;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}