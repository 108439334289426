import axios from 'axios';
import {userService} from './user.service'
import {notificationService} from "./notification.service";

function setupApiClient() {
    let client = axios.create();
    client.interceptors.request.use(
        function (config) {
            const token = JSON.parse(localStorage.getItem('token'));
            config.headers.Authorization = (token && token.access_token) ? `Bearer ${token.access_token}` : '';
            return config
        },
        function (error) {
            return Promise.reject(error);
        }
    );
    client.interceptors.response.use(
        function (response) {
            return response;
        },
        async function (error) {
            const originalRequest = error.config;
            if (error.response.status === 401 && originalRequest.url.includes("auth/token")) {
                localStorage.removeItem('token');
                return Promise.reject(error);
            } else if (error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                await userService.refreshToken()
                return client(originalRequest);
            } else if (error.response.status === 422) {
                for (const err of error.response.data.errors) {
                    notificationService.sendError(err.msg);
                }
                return Promise.reject(error);
            } else if (error.response.status === 500) {
            notificationService.sendError(`${error.response.status}: ${error.response.statusText}. ${error.response.data.data}`);
                return Promise.reject(error);
            } else if (error.response.status === 502) {
                notificationService.sendError(`502: ${error.response.data.data}`);
                return Promise.reject(error);
            }
            return Promise.reject(error);
        }
    );
    return client
}

export const apiClient = setupApiClient();

