import {createRouter, createWebHistory} from "vue-router";
import PublicFiles from '../components/PublicFileList';
import PrivateFiles from '../components/PrivateFileList';
import DocumentsFiles from '../components/DocumentsFileList';
import MediaSave from '../components/MediaSave';
import LoginPage from '../components/LoginPage';
import PageNotFound from '../components/PageNotFound';
import {Modal} from 'ant-design-vue';

const routes = [
    {
        path: "/",
        name: "Home",
        component: PublicFiles,
    },
    {
        path: "/hidden-media",
        name: "Private",
        component: PrivateFiles,
    },
    {
        path: "/documents",
        name: "Documents",
        component: DocumentsFiles,
    },
    {
        path: "/media-save",
        name: "MediaSave",
        component: MediaSave,
    },
    {
        path: "/login",
        name: "Login",
        component: LoginPage,
    },
    {
        path: "/:catchAll(.*)",
        name: "NotFound",
        component: PageNotFound,
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('token');

    if (authRequired && !loggedIn) {
        return next('/login');
    }
    Modal.destroyAll();

    next();
})

export default router;