import {apiClient} from "@/_services/api-client";
import {store} from '../store';

export const appSettingsService = {
    getSettings,
};

async function getSettings() {
    store.commit("loading/loading", true);
    try {
        const response = await apiClient.get(`/api/v1/secure-links/app-settings`);
        localStorage.setItem('settings', JSON.stringify(response.data));
        return response.data;
    } finally {
        store.commit("loading/loading", false);
    }
}