import {appSettingsService} from '@/_services';

const settings = JSON.parse(localStorage.getItem('settings'));

const state = () => ({
    settings: settings || {},
})

const getters = {}
const actions = {
    fetchSettings({commit}) {
        appSettingsService.getSettings().then(settings => {
            commit('saveSettings', settings);
        });
    },
}
const mutations = {
    saveSettings(state, settings) {
        state.settings = settings;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}