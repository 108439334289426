import {notification} from 'ant-design-vue';

export const notificationService = {
    sendInfo,
    sendError,
};

function sendInfo(message, description) {
    notification.info({
        message: message,
        description: description
    });
}

function sendError(message, description) {
    notification.error({
        message: message,
        description: description
    });
}


