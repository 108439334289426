import {mediaSaveService, notificationService} from '@/_services';

const state = () => ({
    mediaInfo: null,
    scheduledTasks: []
})

const getters = {}
const actions = {
    fetchMediaInfo({commit}, url) {
        mediaSaveService.fetchMediaInfo(url).then(info => {
            commit('saveMediaInfo', info.data);
        });
    },
    downloadMedia({commit, state, dispatch}, payload) {
        mediaSaveService.downloadMedia(payload).then(() => {
            notificationService.sendInfo(`Загрузка ${state.mediaInfo.title} началась`)
            commit('clearMediaInfo');
            dispatch('fetchScheduledTasks');
        }, error => {
            notificationService.sendError(error.response.data);
        });
    },
    fetchScheduledTasks({commit}) {
        mediaSaveService.fetchScheduledTasks().then(tasks => {
            commit('saveScheduledTasks', tasks.data);
        });
    },
    stopTask({dispatch}, pid) {
        mediaSaveService.stopTask(pid).then(() => {
            notificationService.sendInfo('Задание остановлено');
            dispatch('fetchScheduledTasks');
        })
    }
}

const mutations = {
    saveMediaInfo(state, info) {
        state.mediaInfo = info;
    },
    clearMediaInfo(state) {
        state.mediaInfo = null;
    },
    saveScheduledTasks(state, tasks) {
        state.scheduledTasks = tasks;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}