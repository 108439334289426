<template>
  <a-table :columns="columns" :data-source="scheduledTasksData">
    <template #title>
      <a-page-header :title="currentMediaInfo.title"/>
      <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item label="Ссылка на медиа" v-bind="validateInfos.name">
          <a-input-search v-model:value="modelRef.name" :loading="loading"
                          @search="onSearch"/>
        </a-form-item>
        <a-form-item label="Качество" v-if="currentMediaInfo.formats"
                     v-bind="validateInfos.quality">
          <a-select v-model:value="modelRef.quality"
                    placeholder="Выберите качество медиа">
            <a-select-option
                v-for="format in currentMediaInfo.formats"
                v-bind:key="format.format_id"
                :value="format.format_id"
            >
              {{ format.format }} ({{ humanFileSize(format.filesize) }})
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button
              :disabled="!currentMediaInfo.title"
              type="primary"
              @click.prevent="onSubmit"
          >Сохранить
          </a-button>
          <a-button
              style="margin-left: 10px"
              @click="resetFields"
          >
            Сбросить
          </a-button>
        </a-form-item>
      </a-form>
    </template>
    <template #thumbnail="{ record }">
      <a-image :width="200" :src="record.thumbnail"/>
    </template>
    <template #contentTitle="{ record }">
      <a target="_blank" :href="record.url">{{ record.title }}</a>
      <a-divider/>
      <a-tag
          v-if="!record.existsOnFs"
          color="volcano"
      >
        Не найден на диске
      </a-tag>
      <a-tag
          v-else
          color="green"
      >
        Файл сохранен
      </a-tag>
      <a-tag
          v-if="record.streamIsRecording"
          color="yellow"
      >
        Стрим пишется в данный момент
      </a-tag>
    </template>
    <template #createdAtTitle="{ record }">
      {{ humanizeDate(record.createdAt) }}
    </template>
    <template #customTitleActions>
      <span>Действия</span>
      <a-divider type="vertical"></a-divider>
      <a-button @click="handleRefresh">
        <template #icon>
          <RedoOutlined/>
        </template>
        Обновить
      </a-button>
    </template>
    <template #actions="{ record }">
      <a-button
          v-if="record.isRunning"
          type="danger"
          size="large"
          @click="handleStopRecord(record.pid)"
      >
        Завершить запись
      </a-button>
    </template>
  </a-table>
</template>

<script>
import {useStore} from 'vuex';
import {computed, defineComponent, onMounted, reactive} from 'vue';
import {useForm} from '@ant-design-vue/use';
import {humanFileSize, humanizeDate} from "@/_helpers";
import {RedoOutlined} from '@ant-design/icons-vue';
import {notificationService} from "../_services";

const columns = [
  {
    title: "Картинка",
    dataIndex: 'thumbnail',
    key: 'thumbnail',
    slots: {
      customRender: 'thumbnail',
    },
  },
  {
    title: "Название",
    dataIndex: 'title',
    key: 'title',
    slots: {
      customRender: 'contentTitle',
    },
  },
  {
    title: "Задание создано",
    dataIndex: 'createdAt',
    key: 'createdAt',
    slots: {
      customRender: 'createdAtTitle',
    },
  },
  {
    dataIndex: 'actions',
    key: 'actions',
    slots: {
      customRender: 'actions',
      title: 'customTitleActions',
    },
  },
];

export default defineComponent({
  name: "MediaSave",
  components: {RedoOutlined},
  setup() {
    document.title = "Публичные ссылки - Ямал-Медиа | Сохранить из видеохостинга";
    onMounted(() => {
      store.dispatch('mediaInfo/fetchScheduledTasks');
    })

    const store = new useStore();

    const modelRef = reactive({
      name: '',
      quality: undefined,
    });

    const rulesRef = reactive({
      name: [
        {
          required: true,
          message: 'Введите ссылку на контент',
        },
      ],
      quality: [
        {
          required: true,
          message: 'Выберите качество',
        },
      ],
    });
    const {resetFields, validate, validateInfos} = useForm(modelRef, rulesRef);

    const scheduledTasksData = computed(() => {
      const tasks = store.state.mediaInfo.scheduledTasks;
      return tasks.map(v => (
              {
                thumbnail: v.media_info.thumbnail,
                title: v.media_info.title,
                url: v.url,
                key: v._id,
                isRunning: v.ffmpeg_pid !== null,
                pid: v.pid,
                existsOnFs: v.exists_on_fs,
                createdAt: v.created_at,
                streamIsRecording: v.exists_on_fs && v.ffmpeg_pid !== null && v.media_info.is_live
              }
          )
      )
    })

    const onSubmit = () => {
      let payload = {
        url: modelRef.name,
        format_id: modelRef.quality,
        title: currentMediaInfo.value.title
      }
      validate().then(() => {
        store.dispatch('mediaInfo/downloadMedia', payload);
      });
    };

    const onSearch = text => {
      if (text.length > 0) {
        store.dispatch('mediaInfo/fetchMediaInfo', text);
      }
    };

    const handleStopRecord = pid => {
      store.dispatch('mediaInfo/stopTask', pid);
    };

    const handleRefresh = () => {
      store.dispatch('mediaInfo/fetchScheduledTasks')
          .then(notificationService.sendInfo('Обновлено'));
    };

    const loading = computed(() => {
      return store.state.loading.isLoading;
    });

    const currentMediaInfo = computed(() => {
      const result = store.state.mediaInfo.mediaInfo;
      return result === null ? {} : result;
    });

    return {
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 14,
      },
      validateInfos,
      resetFields,
      modelRef,
      onSubmit,
      loading,
      currentMediaInfo,
      onSearch,
      handleStopRecord,
      handleRefresh,
      humanFileSize,
      scheduledTasksData,
      columns,
      humanizeDate,
    };
  },
});

</script>

<style scoped>

</style>