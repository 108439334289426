import {apiClient} from "@/_services/api-client";
import {store} from '../store';
import {notificationService} from './notification.service';

export const mediaSaveService = {
    fetchMediaInfo,
    downloadMedia,
    fetchScheduledTasks,
    stopTask,
};

async function fetchMediaInfo(url) {
    store.commit("loading/loading", true);
    try {
        const response = await apiClient.post(`/api/v1/media-save/get-media-info`, {url: url});
        notificationService.sendInfo(`Информация загружена за ${parseFloat(response.data.time).toFixed(2)} секунд(ы)`)
        return response.data;
    } finally {
        store.commit("loading/loading", false);
    }
}

async function downloadMedia(payload) {
    store.commit("loading/loading", true);
    try {
        const response = await apiClient.post(`/api/v1/media-save/download-media`, payload);
        return response.data;
    } finally {
        store.commit("loading/loading", false);
    }
}

async function fetchScheduledTasks() {
    store.commit("loading/loading", true);
    try {
        const response = await apiClient.get(`api/v1/media-save/scheduled-task-list`);
        return response.data;
    } finally {
        store.commit("loading/loading", false);
    }
}

async function stopTask(pid) {
    store.commit("loading/loading", true);
    try {
        const response = await apiClient.post(`/api/v1/media-save/stop-task/${pid}`);
        return response.data;
    } finally {
        store.commit("loading/loading", false);
    }
}