import {notificationService, userService} from '@/_services';

const token = JSON.parse(localStorage.getItem('token'));

const state = () => ({
    token: token,
    user: null
})

const getters = {
    loggedIn: state => {
        return state.token !== null;
    },
}

const actions = {
    login({commit, dispatch}, {username, password}) {
        userService.login(username, password).then(token => {
                commit('setToken', token);
                notificationService.sendInfo(`Успешная авторизация, ${username}`);
                dispatch('getUserInfo');
            },
            error => {
                notificationService.sendError(error.response.data.detail);
            }
        );
    },
    logout({commit}) {
        userService.logout().then(() => {
                commit('logout');
                notificationService.sendInfo('Вы успешно вышли из системы');
            },
            error => {
                notificationService.sendError(error.response.data.detail);
            })
    },
    getUserInfo({commit}) {
        userService.fetchUserInfo().then(user => {
            commit('setUser', user);
        });
    }
}

const mutations = {
    setToken(state, token) {
        state.token = token;
    },
    setUser(state, user) {
        state.user = user;
    },
    logout(state) {
        state.user = null;
        state.token = null;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}