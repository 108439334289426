<template>
  <a-page-header
      :ghost="false"
      :title="displayPath"
  >
    <a-descriptions size="small" bordered layout="vertical">
      <a-descriptions-item label="Время жизни ссылки (дней)">
        <a-slider v-model:value="urlExpires" :min="minUrlExpires" :max="maxUrlExpires"/>
      </a-descriptions-item>
      <a-descriptions-item label="Действия">
        <a-checkbox v-model:checked="filterState.noLinksOnlyChecked">Только без ссылок</a-checkbox>
        <a-divider type="vertical"></a-divider>
        <a-button @click="handleRefresh">
          <template #icon>
            <RedoOutlined/>
          </template>
          Обновить
        </a-button>
      </a-descriptions-item>
      <a-descriptions-item label="Количество файлов">
        <span>{{ files.length }}</span>
      </a-descriptions-item>
    </a-descriptions>
  </a-page-header>
  <a-table
      :columns="columns"
      :data-source="files"
      :rowKey="row=>row.name"
      :loading="loading"
      layout="fixed"
      :pagination="false"
      @change="handleTableChange"
  >
    <template #title>
    </template>
    <template #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
      <div style="padding: 8px">
        <a-input
            ref="searchInput"
            :placeholder="`Поиск: ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
        />
        <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
        >
          <template #icon>
            <SearchOutlined/>
          </template>
          Искать
        </a-button>
        <a-button size="small" style="width: 90px"
                  @click="handleReset(clearFilters)">
          Сбросить
        </a-button>
      </div>
    </template>
    <template #filterIcon="filtered">
      <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }"/>
    </template>
    <template #filename="{text, record}">
      <a-badge v-if="record.links.length === 0 && !record.is_dir" status="success"/>
      <span v-if="record.is_dir" style="text-decoration: line-through;">{{ text }}</span>
      <span v-else> {{ text }}</span>
    </template>
    <template #filesize="{text}">
      {{ humanFileSize(text) }}
    </template>
    <template #modified="{text}">
      {{ humanizeDate(text) }}
    </template>
    <template #operation="{record}">
      <a-popover v-if="record.is_dir" title="Невозможно создать ссылку на папку">
        <template #content>
          <p>Чтобы создать ссылку на папку с файлами, создайте архив: rar, tar или zip</p>
        </template>
        <a-button type="primary" disabled>
          <LinkOutlined/>
          Создать ссылку
        </a-button>
      </a-popover>
      <secure-link-dialog
          v-else
          :record="record"
          :url-expires="urlExpires"
          :context="context"
          :after-close-dialog="afterCloseDialog"
      />
    </template>
    <template #expandedRowRender="{record}">
      <a-table
          :columns="innerColumns"
          :data-source="record.links"
          :pagination="false"
          :rowKey="row=>row.secure_url_path"
          size="small"
      >
        <template #createdAt="{text}">
          {{ humanizeDate(text) }}
        </template>

        <template #expiredAt="{text}">
          <span>
            <a-badge :status="dateExpired(text) ? 'success' : 'error'"/>{{ humanizeDate(text) }}
          </span>
        </template>
        <template #secureUrlPath="{text, index}">
          <p>
            <span class="table-operation">
              <a @click="copyLink(text);">Скопировать ссылку</a>
            </span>
          </p>
          <p>
            <span class="table-operation">
              <a @click="copyMessage(record, index);">Скопировать сообщение</a>
            </span>
          </p>
        </template>
      </a-table>
    </template>
  </a-table>
</template>

<script>
import {LinkOutlined, RedoOutlined, SearchOutlined} from '@ant-design/icons-vue';
import {computed, defineComponent, reactive, ref, watch} from 'vue';
import {useStore} from 'vuex';
import {dateExpired, humanFileSize, humanizeDate, createLink, createMessage} from "@/_helpers";
import {notificationService} from "@/_services";
import {toClipboard} from '@soerenmartius/vue3-clipboard';
import SecureLinkDialog from './SecureLinkDialog';

export default defineComponent({
  name: "FileListView",
  components: {
    SearchOutlined,
    LinkOutlined,
    RedoOutlined,
    SecureLinkDialog,
  },

  props: {
    files: Array,
    context: String,
    filterData: {
      type: Object,
      default: function () {
        return {
          searchText: '',
          searchedColumn: '',
          noLinksOnlyChecked: false,
          orderBy: '-modified',
        }
      }
    }
  },

  setup(props) {
    const store = new useStore();

    const columns = [
      {
        title: 'Имя файла',
        dataIndex: 'name',
        key: 'filename',
        sorter: true,
        slots: {
          customRender: 'filename',
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon',
          onFilter: (value, record) =>
              record.name.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                searchInput.value.focus();
              }, 0);
            }
          },
        },
        ellipsis: true,
      },
      {
        title: 'Размер',
        dataIndex: 'size_bytes',
        key: 'filesize',
        sorter: true,
        slots: {
          customRender: 'filesize',
        },
        width: '10%',
      },
      {
        title: 'Дата изменения',
        dataIndex: 'modified',
        key: 'modified',
        sorter: true,
        slots: {
          customRender: 'modified',
        },
        width: '15%',
      },
      {
        title: 'Действия',
        key: 'operation',
        slots: {
          customRender: 'operation',
        },
        width: '15%',
      },
    ];

    const innerColumns = [
      {
        title: 'Кто создал',
        dataIndex: 'created_by',
        key: 'created_by',
      },
      {
        title: 'Время создания',
        dataIndex: 'created_at',
        key: 'created_at',
        slots: {
          customRender: 'createdAt',
        },
        width: '15%',
      },
      {
        title: 'Истекает',
        dataIndex: 'expired_at',
        key: 'expired_at',
        slots: {
          customRender: 'expiredAt',
        },
        width: '15%',
      },
      {
        title: 'Ссылка',
        dataIndex: 'secure_url_path',
        key: 'secure_url_path',
        slots: {
          customRender: 'secureUrlPath',
        },
      },
    ];

    const searchInput = ref();

    const filterState = reactive({
      searchText: props.filterData.searchText,
      searchedColumn: props.filterData.searchedColumn,
      noLinksOnlyChecked: props.filterData.noLinksOnlyChecked,
      orderBy: props.filterData.orderBy,
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      filterState.searchText = selectedKeys[0];
      filterState.searchedColumn = dataIndex;
    };

    const handleReset = clearFilters => {
      clearFilters();
      filterState.searchText = '';
    };

    const loading = computed(() => {
      return store.state.loading.isLoading;
    });

    const urlExpires = ref(5);

    const appSettings = computed(() => {
      return store.state.appSettings.settings;
    })

    const minUrlExpires = Math.floor(appSettings.value.minUrlExpires / 86400);

    const maxUrlExpires = Math.floor(appSettings.value.maxUrlExpires / 86400);

    const displayPath = computed(() => {
      return {
        public: appSettings.value.publicDisplayPath || "Создание публичных ссылок на файлы",
        private: appSettings.value.privateDisplayPath || "Создание публичных ссылок на файлы",
        documents: appSettings.value.documentsDisplayPath || "Создание ссылок на документы",
      }[props.context];
    })

    watch(
        () => filterState.noLinksOnlyChecked, (curr) => {
          fetchFilteredContext({noLinks: curr})
        }
    );

    const handleTableChange = (page, filters, sorter) => {
      let searchQuery = {noLinks: filterState.noLinksOnlyChecked};
      if ("column" in sorter) {
        let orderBy = sorter.column.dataIndex;
        if (sorter.order === 'descend') {
          orderBy = `-${orderBy}`
        }
        searchQuery['orderBy'] = orderBy;
      }
      if (filters.filename) {
        searchQuery['searchQuery'] = filters.filename;
      }
      fetchFilteredContext(searchQuery);
    };

    const fetchFilteredContext = payload => {
      const actionContext = {
        public: 'fileList/fetchPublicFiltered',
        private: 'fileList/fetchPrivateFiltered',
      }
      store.dispatch(actionContext[props.context], payload)
    }

    const handleRefresh = () => {
      let currentFilterQuery = {
        orderBy: filterState.orderBy,
        noLinks: filterState.noLinksOnlyChecked
      }
      if (filterState.searchText) {
        currentFilterQuery['searchQuery'] = filterState.searchText;
      }
      fetchFilteredContext(currentFilterQuery);
    }

    const copyLink = text => {
      const secureLinkText = createLink(text)
      toClipboard(secureLinkText).then(() => notificationService.sendInfo('Ссылка скопирована'));
    }

    const renameKey = (object, key, newKey) => {
      const clonedObj = clone(object);
      const targetKey = clonedObj[key];
      delete clonedObj[key];
      clonedObj[newKey] = targetKey;
      return clonedObj;
    };

    const clone = (obj) => Object.assign({}, obj);

    const copyMessage = (record, index) => {
      let secureLink = record.links[index]
      secureLink = renameKey(secureLink, 'secure_url_path', 'data')
      const extraText = createMessage(record, secureLink)
      toClipboard(extraText).then(() => notificationService.sendInfo('Сообщение скопировано'));
    }

    const afterCloseDialog = () => {
      handleRefresh();
    }

    return {
      columns,
      innerColumns,
      loading,
      humanizeDate,
      humanFileSize,
      appSettings,
      urlExpires,
      minUrlExpires,
      maxUrlExpires,
      displayPath,
      filterState,
      handleTableChange,
      handleSearch,
      handleReset,
      copyLink,
      copyMessage,
      dateExpired,
      handleRefresh,
      afterCloseDialog,
    };
  },
});


</script>

<style scoped>

</style>