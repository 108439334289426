import {apiClient} from "@/_services/api-client";
import {notificationService} from './notification.service';
import {store} from '../store';

export const secureLinkService = {
    getAllPublic,
    getFilteredPublic,
    getAllPrivate,
    getFilteredPrivate,
    getAllDocuments,
    getFilteredDocuments,
    createLink,
};

async function getAllPublic() {
    store.commit("loading/loading", true);
    try {
        const response = await apiClient.get(`/api/v1/secure-links/list-dir/public`);
        return response.data;
    } finally {
        store.commit("loading/loading", false);
    }
}

async function getFilteredPublic(payload) {
    store.commit("loading/loading", true);
    let params = payload
    params = new URLSearchParams(params).toString()
    try {
        const response = await apiClient.get(`/api/v1/secure-links/list-dir/public?${params}`);
        return response.data;

    } finally {
        store.commit("loading/loading", false);
    }
}

async function getAllPrivate() {
    store.commit("loading/loading", true);
    try {
        const response = await apiClient.get(`/api/v1/secure-links/list-dir/private`);
        return response.data;
    } finally {
        store.commit("loading/loading", false);
    }
}

async function getFilteredPrivate(payload) {
    store.commit("loading/loading", true);
    let params = payload
    params = new URLSearchParams(params).toString()
    try {
        const response = await apiClient.get(`/api/v1/secure-links/list-dir/private?${params}`);
        return response.data;
    } finally {
        store.commit("loading/loading", false);
    }
}

async function getAllDocuments() {
    store.commit("loading/loading", true);
    try {
        const response = await apiClient.get(`/api/v1/secure-links/list-dir/documents`);
        return response.data;
    } finally {
        store.commit("loading/loading", false);
    }
}

async function getFilteredDocuments(payload) {
    store.commit("loading/loading", true);
    let params = payload
    params = new URLSearchParams(params).toString()
    try {
        const response = await apiClient.get(`/api/v1/secure-links/list-dir/documents?${params}`);
        return response.data;
    } finally {
        store.commit("loading/loading", false);
    }
}

async function createLink(filename, ttl, context) {
    store.commit("loading/loading", true);
    const payload = {filename: filename, ttl: ttl}
    try {
        const response = await apiClient.post(`/api/v1/secure-links/${context}/create-secure-link`, payload);
        return response.data;
    } catch (e) {
        notificationService.sendError(e.response.data.detail);
        throw new Error(e);
    } finally {
        store.commit("loading/loading", false);
    }
}