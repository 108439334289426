import App from './App'
import {createApp} from 'vue';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import router from './router'
import {store} from './store'
import { VueClipboard } from '@soerenmartius/vue3-clipboard'

const app = createApp(App);

app.use(router);
app.use(store);
app.use(Antd);
app.use(VueClipboard);

app.mount('#app');
